<template>
  <div
    class="mt-4"
  >
    <v-container>
      <v-row class="pa-0">
        <v-col
          cols="12"
          class="pa-0 info--text text-center mb-2 d-flex justify-center align-center"
        >
          <span class="caption px-3 comment--text">
            {{ $t('form.continueWith') }}
          </span>
        </v-col>

        <v-col
          v-if="googleSignInDisplay && !googleAuthError && !isWebview"
          id="google-auth-button-col"
          class="pa-0 mr-1 google rounded"
        >
          <!-- google auth render 按鈕 -->
          <div
            id="google-login-button"
            ref="googleAuth"
            class="google g_id_signin"
          />

          <!-- Google icon -->
          <div
            class="google-icon-area d-flex justify-center align-center"
          >
            <img
              id="google-icon"
              width="24"
              :src="socialMediaItem['google']"
            >
          </div>
        </v-col>

        <!-- google-auth-ios-webview -->
        <v-col
          v-if="googleSignInDisplay && device === 'ios' && isWebview"
          id="google-auth-button-col"
          class="pa-0 mr-1 google rounded"
          @click="iosWebviewEvent"
        >
          <div
            id="google-auth-button-ios"
            class="google-icon-area d-flex justify-center align-center w-100 h-100"
          >
            <img
              id="google-icon"
              width="24"
              :src="socialMediaItem['google']"
              @click="iosWebviewEvent"
            >
            <!-- <span class="caption">w-i</span> -->
          </div>
        </v-col>

        <!-- google-auth-android-webview -->
        <v-col
          v-if="googleSignInDisplay && device === 'android' && isWebview"
          id="google-auth-button-col"
          class="pa-0 mr-1 google rounded"
          @click="androidWebviewEvent"
        >
          <div
            id="google-auth-button-android"
            class="google-icon-area d-flex justify-center align-center"
          >
            <img
              id="google-icon"
              width="24"
              :src="socialMediaItem['google']"
            >
            <!-- <span class="caption">w-a</span> -->
          </div>
        </v-col>

        <!-- APPLE AUTH BUTTON -->
        <v-col
          v-if="appleSignInDisplay && !appleAuthError"
          id="apple-auth-button-col"
          class="pa-0 ml-1 apple rounded overflow-hidden"
        >
          <div>
            <div
              id="appleid-signin"
              data-color="black"
              :data-height="layout === 'template3' ? 60 : 40"
              data-type="sign in"
            />

            <!-- apple Icon -->
            <div class="apple-icon-area d-flex justify-center align-center">
              <img
                id="google-icon"
                width="24"
                :src="socialMediaItem['apple']"
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- 無法使用 google auth -->
    <Dialog
      :dialog="errorDialog.status"
      :width="280"
    >
      <div
        slot="text"
        class="px-6 mb-0"
      >
        <p class="text-center">
          <Icon
            class="danger--text"
            data="@icon/alert/error.svg"
            width="30px"
            height="30px"
          />
        </p>
        <span>
          {{ $t('form.mediaSignInFail', { media: errorSocialMedia }) }}
        </span>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import apple from '../../assets/icons/apple-login.webp'
import google from '../../assets/icons/google-login.webp'
import { resStatus } from '../../utils/resUtils'
import Dialog from '../../components/dialog/index.vue'
import { isWebview, deviceCheck } from '../../utils/device'

export default {
  components: {
    Dialog,
  },

  data: () => ({
    socialMediaItem: {
      apple,
      google,
    },
    errorDialog: {
      status: false,
    },

    // auth fail
    googleAuthError: false,
    appleAuthError: false,
    errorSocialMedia: '',
  }),

  computed: {
    ...mapGetters([
      'googleSignInDisplay',
      'appleSignInDisplay',
      'appleClientID',
      'googleClientID',
      'layout',
    ]),

    redirectUrl() {
      const { href } = this.$router.resolve({ path: '/login' })
      return `${window.location.origin}/${href}?gtoken=`
    },

    isWebview() {
      return isWebview()
    },

    device() {
      return deviceCheck()
    },
  },

  mounted() {
    window.googleAuthHandle = (idToken) => this.handleCallbackForWebview(idToken)

    const googleIdToken = this.$route.query.gtoken
    if (googleIdToken) {
      this.$log(googleIdToken)
      this.handleCallbackForWebview(googleIdToken)
      return false
    }

    // 如果顯示 google 登入
    if (this.googleSignInDisplay) {
      this.googleAuthInit()
    }

    // APPLE AUTH
    this.appleAuthInit()
    this.appleSignInEventInit()
  },

  updated() {
    this.$nextTick(() => {
      const googleIdToken = this.$route.query.gtoken
      if (googleIdToken) {
        this.handleCallbackForWebview(googleIdToken)
        return false
      }
    })
  },

  methods: {
    ...mapActions([
      'show_alert',
      'set_website',
      'set_user_data',
      'google_sign_in',
      'apple_sign_in',
    ]),

    iosWebviewEvent() {
      const url = this.redirectUrl
      this.$log('iOS Google Auth', url)

      window.webkit.messageHandlers.openGoogle.postMessage(url)
    },

    androidWebviewEvent() {
      const url = this.redirectUrl
      this.$log('android Google Auth', url)

      window.WebScript.openGoogle(url)
    },

    async appleSignInSuccess(event) {
      // Handle successful response.
      const res = await this.apple_sign_in({
        website: await this.set_website(),
        id_token: event.detail.authorization.id_token,
      })
      resStatus(res, this.googleSignInSuccess, this.googleSignInFail)
    },

    appleSignInFail(event) {
      // Handle error.
      // Listen for authorization failures.
      console.log('Apple Auth error:')
      console.log(event)
    },

    appleSignInEventInit() {
      document.addEventListener('AppleIDSignInOnSuccess', this.appleSignInSuccess)
      document.addEventListener('AppleIDSignInOnFailure', this.appleSignInFail)
    },

    // 初始化 apple signIn button
    async appleAuthInit() {
      this.$log(`redirectUrl: ${window.location.origin}`)
      const script = document.createElement('script')
      script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
      document.head.appendChild(script)
      try {
        setTimeout(() => {
          window?.AppleID?.auth.init({
            clientId: this.appleClientID,
            scope: 'name email',
            redirectURI: window.location.origin,
            state: 'CSet3iNPHdqp',
            nonce: 'CSet3iNPHdqp',
            usePopup: true,
          })
        }, 1200)
      } catch (error) {
        this.appleAuthError = true
        setTimeout(() => {
          this.errorSocialMedia = 'Apple'
          this.errorDialog.status = true
          setTimeout(() => {
            this.errorDialog.status = false
            this.errorSocialMedia = ''
          }, 2000)
        }, 2000)
      }
    },

    // 初始化 google 登入
    async googleAuthInit() {
      /* global google */
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      document.head.appendChild(script)
      try {
        setTimeout(() => {
          window?.google?.accounts.id.initialize({
            client_id: this.googleClientID,
            callback: this.handleCallback,
            auto_select: true,
          })
          // 渲染按鈕
          window.google.accounts.id.renderButton(
            this.$refs.googleAuth, {
              width: 400,
            },
          )
        }, 1200)
      } catch {
        if (this.isWebview) return false

        this.googleAuthError = true
        this.errorSocialMedia = 'Google'
        this.errorDialog.status = true
        setTimeout(() => {
          this.errorDialog.status = false
          this.errorSocialMedia = ''
        }, 2000)
      }
    },

    async googleSignInSuccess(data) {
      await this.show_alert({
        icon: 'done',
      })
      await this.$router.replace({ path: '/' }).catch(error => {
        this.$log(error)
      })
      await this.set_user_data(data)
    },

    googleSignInFail() {
      this.show_alert({
        icon: 'fail',
        text: 'Token fail',
      })
    },

    // Google Auth Callback
    async handleCallback({ credential }) {
      try {
        const params = {
          website: await this.set_website(),
          id_token: credential, // google id token
        }

        const res = await this.google_sign_in(params)
        resStatus(res, this.googleSignInSuccess, this.googleSignInFail)
      } catch (error) {
        this.$log(error)
        this.show_alert({
          icon: 'fail',
          text: 'TKF',
        })
      }
    },

    // Webview 使用全域函式
    async handleCallbackForWebview(credential) {
      try {
        const params = {
          website: await this.set_website(),
          id_token: credential, // google id token
        }

        const res = await this.google_sign_in(params)
        resStatus(res, this.googleSignInSuccess, this.googleSignInFail)
      } catch (error) {
        this.$log(error)
        this.show_alert({
          icon: 'fail',
          text: 'token fail',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.info--text::before, .info--text::after {
  content: '';
  height: 1px;
  flex: 1;
  background: #d9d9d9;
}

// google 登入渲染按鈕
#google-login-button{
  opacity: .001;
}

#google-auth-button-col, #apple-auth-button-col,
#google-auth-button-ios-col,
#google-auth-button-android-col {
  position: relative;
  overflow: hidden;
}

.google-auth-icon-button{
  height: 0;
  opacity: 1;
}

#google-auth-button-col::before,
#google-auth-button-android-col::before,
#google-auth-button-ios-col::before {
  content: '';
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  top:0;
  left:0;
  right: 0;
  bottom: 0;
}

#apple-auth-button-col::before{
  content: '';
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  top:0;
  left:0;
  right: 0;
  bottom: 0;
}
.google-icon-area, .apple-icon-area{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

#appleid-signin {
  opacity: .001;
}

#google-auth-button-android, #google-auth-button-ios {
  height: 40px;
}
</style>
