import store from '../store'
import { resStatus } from './resUtils'

/**
 * 發送驗証碼 按鈕
 * 倒數 / 狀態重置
 * @date 2023-05-26
 * @param {obj} dataTarget  data 資料
 * @param {number} verifiMobileCodeBtnSec 倒數秒數
 * @param {number} phoneCountryCode 國碼
 * @param {number} form.contact_no  手機號碼
 */
export function verifiMobileCode(dataTarget) {
  // 發送 sms 簡訊
  if (dataTarget.verifiMobileCodeBtnSec !== 60) return false
  sendMobileVerifyCode({
    phone: `${dataTarget.phoneCountryCode}|${dataTarget.form.contact_no}`,
  })
  // 倒數計時
  reciprocal(dataTarget)
}

/**
 * 倒數功能 (發送驗証碼)
 * @date 2023-05-26
 * @param {number} verifiMobileCodeBtnSec 倒數秒數
 * @param {number} formContact_no  手機號碼
 * @param {obj} dataTarget  data 資料
 */
function reciprocal(dataTarget) {
  // disable 發送驗証碼 btn
  dataTarget.disable.verifiMobileCode = true
  const timeId = setInterval(() => {
    if (dataTarget.verifiMobileCodeBtnSec === 0) {
      // 如果為 0 即重置
      clearInterval(timeId)
      dataTarget.verifiMobileCodeBtnSec = 60
      if (dataTarget.form.contact_no) {
        dataTarget.disable.verifiMobileCode = false
      }
      // 每秒遞減
    } else dataTarget.verifiMobileCodeBtnSec--
  }, 1000)
  return timeId
}

/**
 * 發送 sms 簡訊驗証
 * @date 2021-05-26
 * @param {String} phone 手機號碼
 */
const sendMobileVerifyCode = async(phone) => {
  const res = await store.dispatch('get_mobile_verify_SMS', phone)
  await resStatus(
    res,
    sendMobileVerifyCodeSuccess,
    sendMobileVerifyCodeFail,
  )
}

/**
 * 發送 sms 簡訊 (成功)
 * @date 2022-01-14
 * @param {array} data 成功信息
 */
function sendMobileVerifyCodeSuccess(data) {
  store.dispatch('show_alert', {
    icon: 'done',
    text: data,
  })
}

/**
 * 發送 sms 簡訊 (失敗)
 * @date 2022-01-14
 * @param {string} errorsMessage 失敗信息
 */
function sendMobileVerifyCodeFail(errorsMessage) {
  store.dispatch('show_alert', {
    icon: 'fail',
    text: errorsMessage,
  })
}
