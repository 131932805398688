import { mapActions, mapGetters, mapState } from 'vuex'
import Dialog from '@/components/dialog'
import { resStatus } from '@/utils/resUtils'
import { decrypt } from '@/utils/cryp'
import { clickEventInterval } from '@/utils/event'
import { isEmail } from 'validator'
import { validateMobileNumberByNation } from '@/utils/validate'
import MediaLogin from '@/components/base/MediaLogin.vue'

import eyeOpen from '@/assets/icons/eye-open.svg'
import eyeClose from '@/assets/icons/eye-close.svg'

export default {
  components: { Dialog, MediaLogin },

  data: () => ({
    phoneCountryCode: null, // 手機前綴國碼
    alert: {
      status: false,
    },

    dialog: {
      status: false,
    },

    form: {
      veridate: false,
      rememberMe: false,
      showPassword: false,

      userName: '',
      password: '',
    },

    eyeOpen,
    eyeClose,

    // 執行過後，是否可以再打 api
    eventLaunch: {
      userLogin: null,
    },

    // forgot password 忘記密碼
    forgotPasswordDialog: {
      resendVerifyCode: {
        num: 60,
        timeId: '',
      },
      status: false,
      verify1: false,
      verify2: false,
      resetStatus: false,
      form: {
        account: '',
        phone: '',
        email: '',
        verifyCode: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
      resetFailDialog: {
        status: false,
        content: '',
      },
    },
  }),

  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      registerStatus: state => state.websiteData.func_switch.allow_register,
      settings: state => state.websiteData.settings,
      serviceUrl: state => state.serviceUrl,

      // 驗證方式
      validateType: state => state.websiteData.validation_method, // none sms email
      pwdRules: state => state.websiteData.password_rule,
      phoneCountryCodeList: state => state.user.phoneCountryCodeList,
      mobileFormatNation: state => state.websiteData.phone_format_nation.toUpperCase(),
    }),

    ...mapGetters(['baseImgUrl', 'websiteLogo', 'websiteName', 'clientLang', 'socialDisplay', 'theme']),

    rules() {
      return {
        requiredAcc: value => !!value || this.$t('form.missAccount'),
        requiredPwd: value => !!value || this.$t('form.missPassword'),
        requiredPhone: value => !!value || this.$t('form.missMobile'),
        requiredEmail: value => !!value || this.$t('form.missEmail'),
        requiredVerifyCode: value => !!value || this.$t('form.missVerifyCode'),
        requiredNewPassword: value => !!value || this.$t('form.enterNewPassword'),
        requiredNewPasswordConfirm: value => !!value || this.$t('form.enterNewPasswordAgain'),
        passwordConfirm: value => value === this.forgotPasswordDialog.form.newPassword || this.$t('form.errorComfirmPassword'),
        isEmail: email => {
          if (email) return isEmail(email) || this.$t('form.errorEmail')
          return ''
        },
        // 只驗証長度
        newPasswordFormat: newPassword => {
          if (!newPassword) return ''

          let oathRule = ''
          this.pwdRules.requirement.forEach(item => {
            oathRule += this.$t(`form.${item}`)
          })

          return (this.pwdRules.size_min <= newPassword.length && newPassword.length <= this.pwdRules.size_max) ||
            this.$t('form.errorPwdFormat', { min: this.pwdRules.size_min, max: this.pwdRules.size_max }) + oathRule
        },
        isMobileByNation: number => {
          if (!number) return this.$t('form.missMobile')
          return validateMobileNumberByNation(number, this.mobileFormatNation) || this.$t('form.errorMobile')
        },
      }
    },

    // 因應語系動態寬度
    itemsWidth() {
      if (this.clientLang === 'ph') return 12
      return 6
    },
  },

  watch: {
    'forgotPasswordDialog.status'(newValue) {
      if (!newValue) {
        this.resetFormVerify1()
        this.resetFormVerify2()
        this.getPhoneCountryCode()
        this.forgotPasswordDialog.resetStatus = false
      }
    },
    'forgotPasswordDialog.resetFailDialog.content'(newContent) {
      // 錯誤內容清空就關閉
      if (newContent) {
        this.forgotPasswordDialog.resetFailDialog.status = true
      } else {
        this.forgotPasswordDialog.resetFailDialog.status = false
      }
    },
  },

  mounted() {
    this.rememberUserAccPwd(localStorage.getItem('IPH_AP'))
    this.getPhoneCountryCode()
    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
  },

  /**
   * 離開 login 頁面
   * 如果 無「記住我」 && 無登入 => 移除使用者 帳密 (localStorage)
   * @date 2021-09-22
   */
  beforeDestroy() {
    if (!this.form.rememberMe && !this.isLogin) this.remove_remember_user_acc_pwd()
  },

  methods: {
    ...mapActions([
      'user_login',
      'show_alert',
      'get_service_url',
      'show_alert',
      'remove_remember_user_acc_pwd',
      'get_service_url',
      'get_phone_country_code',

      'get_mobile_verify_SMS',
      'send_email_validate_code_not_login',
      'reset_user_password_by_no_login',
    ]),

    async getPhoneCountryCode() {
      // 取得手機國碼
      await this.get_phone_country_code()

      // 指定默認 國家手機碼 state.websiteData.phone_format_nation.toUpperCase()
      // this.phoneCountryCode = this.phoneCountryCodeList.find(item => item.code === this.mobileFormatNation).country_code
      // TODO: 預設國家 手機號，但DM88 國家 CHINA，手機號卻是 PH，導致有問題
      this.phoneCountryCode = this.phoneCountryCodeList[0].country_code
    },

    resetForm() {
      this.$refs.form.reset()
      this.form.userName = ''
      this.form.password = ''
      this.form.rememberMe = false
    },

    loginSuccess() {
      this.show_alert({
        icon: 'done',
        // text: this.$t('flashMessage.loginSuccess'),
      })
      this.resetForm()

      setTimeout(() => {
        this.$router.go(-1)
      }, 500)
    },

    loginFail() {
      this.dialog.status = true
      this.resetForm()
    },

    async userLogin() {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.userLogin.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.userLogin.setStatus(false)

      const userData = {
        account: this.form.userName,
        password: this.form.password,
        remember: this.form.rememberMe,
      }
      const res = await this.user_login(userData)
      if (!res) return this.loginFail()
      resStatus(res, this.loginSuccess, this.loginFail)
    },

    /**
     * 取得客服連結 (失敗)
     * @date 2021-08-17
     */
    getServiceUrlFail() {
      this.show_alert({ icon: 'fail' })
    },

    /**
     * 取得客服連結
     * @date 2021-08-17
     */
    getServiceUrl() {
      this.get_service_url()
    },

    /**
     * 另開客服連結頁面
     * @date 2021-08-17
     */
    toServicePage() {
      if (!this.serviceUrl) return this.getServiceUrlFail()
      window.open(this.serviceUrl, '_blank')
    },

    /**
     * 寫入來自 localStorage 使用者帳密
     * @date 2021-09-22
     * @param {String} account 帳號
     * @param {String} password 密碼
     */
    saveAccNPwd(account, password) {
      this.form.userName = account
      this.form.password = password
      this.form.rememberMe = true
    },

    /**
     * (是/否) 記住使用者帳/密
     * 是 => 寫入 帳號 / 密碼 / 打勾「記住我」
     * @date 2021-09-22
     * @param {any} userAccPwd
     */
    rememberUserAccPwd(userAccPwd) {
      if (!userAccPwd) return false
      const { account, password } = JSON.parse(decrypt(userAccPwd))
      this.saveAccNPwd(account, password)
    },

    forgotPassword() {
      // 無設置驗證方式
      if (this.validateType === 'none') return this.$router.push({ path: '/service' })

      this.forgotPasswordDialog.status = true
    },

    changeForgotDialog(newStatus) {
      this.forgotPasswordDialog.status = newStatus
    },

    resetFormVerify1() {
      this.$refs.verify1.reset()
    },
    resetFormVerify2() {
      this.$refs.verify2.reset()
    },

    // 發送手機驗證碼功能
    async sendPhoneVerifyCode(phoneAndAccount) {
      this.$log('發送 SMS 驗證')
      const res = await this.get_mobile_verify_SMS(phoneAndAccount)
      resStatus(res, this.sendVerifyCodeFunSuccess, this.sendVerifyCodeFunFail)
    },

    // 發送 email 功能
    async sendEmailVerifyCode(accountAndPhone) {
      this.$log('發送 email 驗證')
      const res = await this.send_email_validate_code_not_login(accountAndPhone)
      resStatus(res, this.sendVerifyCodeFunSuccess, this.sendVerifyCodeFunFail)
    },

    sendVerifyCodeFunSuccess() {
      // 如果發送成功
      this.show_alert({
        icon: 'done',
      })
      this.forgotPasswordDialog.resetStatus = true
    },

    sendVerifyCodeFunFail() {
      // 發送失敗
      this.show_alert({ icon: 'fail' })
    },

    // 發送驗證碼功能
    async sendVerifyCodeFun() {
      // 倒數功能
      this.resendVerifyCodeWait()

      this.validateType === 'sms'
        ? this.sendPhoneVerifyCode({
          account: this.forgotPasswordDialog.form.account,
          phone: `${this.phoneCountryCode}|${this.forgotPasswordDialog.form.phone}`,
        })
        : this.sendEmailVerifyCode({
          account: this.forgotPasswordDialog.form.account,
          email: this.forgotPasswordDialog.form.email,
        })
    },

    // 發送驗證碼
    async sendVerifyCode() {
      await this.$refs.verify1.validate()
      if (!this.forgotPasswordDialog.verify1) return false

      // 發送驗證碼功能
      this.sendVerifyCodeFun()
    },

    resetForgotForm() {
      this.resetSendVerifyCodeAwait()
      Object.assign(this.$data.forgotPasswordDialog, this.$options.data().forgotPasswordDialog)
    },

    resetPasswordFunFail(error) {
      // 重設密碼失敗
      this.show_alert({ icon: 'fail' })
      this.forgotPasswordDialog.resetFailDialog.content = error
    },

    resetPasswordFunSuccess() {
      // 重設密碼成功
      this.show_alert({
        icon: 'done',
      })
      this.resetForgotForm()
    },

    // 重設密碼功能
    async resetPasswordFun(data) {
      this.$log('重設密碼')

      const res = await this.reset_user_password_by_no_login(data)
      resStatus(res, this.resetPasswordFunSuccess, this.resetPasswordFunFail)
    },

    // 重設密碼
    async resetPasswordSubmit() {
      await this.$refs.verify2.validate()
      if (!this.forgotPasswordDialog.verify2) return false

      const userResetData = {
        account: this.forgotPasswordDialog.form.account,
        new_password: this.forgotPasswordDialog.form.newPassword,
        new_password_confirmation: this.forgotPasswordDialog.form.newPasswordConfirm,

        validate_type: this.validateType, // 驗證方式 (sms/email)
        validate_key: this.forgotPasswordDialog.form[this.validateType === 'sms' ? 'phone' : this.validateType], // 傳送驗證碼 載具 (sms 號 / email 號)
        validate_value: this.forgotPasswordDialog.form.verifyCode, // 傳送之驗證碼
      }

      this.resetPasswordFun(userResetData)
    },

    // 取消倒數功能
    resetSendVerifyCodeAwait() {
      clearInterval(this.forgotPasswordDialog.resendVerifyCode.timeId)
      this.forgotPasswordDialog.resendVerifyCode.num = 60
    },

    // 倒數功能
    resendVerifyCodeWait() {
      this.resetSendVerifyCodeAwait()

      this.forgotPasswordDialog.resendVerifyCode.num--
      this.forgotPasswordDialog.resendVerifyCode.timeId = setInterval(() => {
        if (this.forgotPasswordDialog.resendVerifyCode.num < 1) {
          clearInterval(this.forgotPasswordDialog.resendVerifyCode.timeId)

          this.forgotPasswordDialog.resendVerifyCode.num = 60
          return false
        }

        this.forgotPasswordDialog.resendVerifyCode.num--
      }, 1000)
    },

    // 上一步
    prevStep() {
      this.forgotPasswordDialog.resetStatus = false
      this.resetFormVerify2()
    },
  },
}
