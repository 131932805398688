import { mapActions, mapGetters, mapState } from 'vuex'
import { isValidateAccount as veridateAcc, validateMobileNumberByNation } from '@/utils/validate'
import { resStatus } from '@/utils/resUtils'
import isLocalDev from '@/utils/isLocalDev'
import { clickEventInterval } from '@/utils/event'
import eyeOpen from '@/assets/icons/eye-open.svg'
import eyeClose from '@/assets/icons/eye-close.svg'

import MediaLogin from '@/components/base/MediaLogin.vue'
import Identify from '@/components/verify/Identify.vue'
import Dialog from '@/components/dialog'
import Select from '@/components/dialog/Select'
import { VerificationCodeValidation, verifiMobileCode } from '@/utils/verifiMobileCode'

export default {
  components: {
    Identify,
    Dialog,
    MediaLogin,
    Select,
  },

  data: () => ({
    phoneCountryCode: null, // 手機前綴國碼
    varifyCodeStatus: true,
    verifyCodeFetchTimeId: '',
    verifiMobileCodeBtnSec: 60, // 倒數秒數
    timeId: '', // 計時器
    disable: {
      contact_no: false,
      verifiMobileCode: true, // 發送驗証碼 btn
    },

    dialog: {
      status: false,
      text: '',
    },

    rulesDialog: {
      status: false,
      title: '',
      content: [],
    },

    form: {
      account: '',
      password: '',
      password_confirmation: '',
      website: '',
      verifyCode: '',
      agentCode: '',

      veridate: false,
      checkAge: false,
      showPassword: false,
      showPasswordConfirmation: false,
      contact_no: '', // 手機號碼
      verifiMobileCode: '', // 手機驗証碼
    },

    identifyCode: '1234', // 验证码初始值
    identifyCodes: '1234567890', // 验证码的随机取值范围

    eyeOpen,
    eyeClose,

    // 執行過後，是否可以再打 api
    eventLaunch: {
      userRegister: null,
    },

    registerFailDialog: {
      status: false,
      text: null,
    },

    pwdTipText: false,
  }),

  computed: {
    ...mapState({
      websiteName: state => state.websiteData.site_params?.site_name,
      isLogin: state => state.user.isLogin,
      accountLengthMax: state => state.websiteData.account_size_max,
      settings: state => state.websiteData.settings,
      pwdRules: state => state.websiteData.password_rule,
      phoneCountryCodeList: state => state.user.phoneCountryCodeList,
      // 用於判斷註冊是否需要手機驗證
      registerValidationMethod: state => state.websiteData.register_validation_method, // none sms
    }),
    ...mapGetters(['baseImgUrl', 'websiteLogo', 'websiteName', 'socialDisplay', 'layout', 'theme']),

    /**
     * 驗証規則
     * @date 2021-06-01
     * @returns {boolean || string}
     */
    rules() {
      return {
        // 必填
        require: {
          acc: value => !!value || this.$t('form.missAccount'), // 帳號
          pwd: value => !!value || this.$t('form.missPassword'), // 密碼
          confirmPwd: value => !!value || this.$t('form.confirmPasswordAgain'), // 再次輸入密碼
          mobile: value => {
            // 手機有值 且 為 60 秒
            if (value && this.verifiMobileCodeBtnSec === 60) {
              this.disable.verifiMobileCode = false
            } else if (!value) {
              this.disable.verifiMobileCode = true
            }
            return !!value || this.$t('form.missMobile')
          },
          missMobileVerifyCode: value => !!value || this.$t('form.missMobileVerifyCode'), // 輸入手機驗證碼
          verifyCode: value => !!value || this.$t('form.missVerifyCode'),
          age: value => !!value || this.$t('form.missRegisterChecked'),
        },
        length: {
          acc: value =>
            (value.length >= 5 && value.length <= Number(this.accountLengthMax)) ||
            this.$t('form.errorAccountFormat', { max: Number(this.accountLengthMax) }),
          pwd: value => value.length > 5 || this.$t('form.errorPasswordFormat'),
          missMobileVerifyCode: value => value.length === 6 || this.$t('form.errorCaptcha'), // 輸入手機驗證碼
        },
        format: {
          acc: value =>
            veridateAcc(value) || this.$t('form.errorAccountFormat', { max: Number(this.accountLengthMax) }),
          /**
           * 手機號碼格式確認 (中國電話 13575769526)
           * @date 2021-05-25
           * @param {string} phoneNumber 手機號碼
           * @returns {boolean} 是否符合手機格式
           */
          mobile: phoneNumber => {
            if (this.registerValidationMethod === 'sms') {
              // TODO 泰國語系尚未調整為 'th-TH' (確認 api wtoken/lang 是否為語系代碼) => 調整回 this.lang
              const validate = validateMobileNumberByNation(phoneNumber, this.mobileFormatNation)
              this.$log(`手機號碼驗證: ${validate}`)
              // 發送驗證碼狀態 (如果不在計時中)
              if (this.verifiMobileCodeBtnSec === 60) {
                this.disable.verifiMobileCode = !validate
              }
              return validate || this.$t('form.errorMobile')
            }
            return true
          },
        },
        verify: {
          confirmPwd: value =>
            value === this.form.password ||
            this.$t('form.errorComfirmPassword'),
          verifyCode: value =>
            value === this.identifyCode || this.$t('form.errorVerifyCode'),
        },
      }
    },

    // website 設定
    website() {
      // 是否本地開發
      const website = isLocalDev()
        ? process.env.VUE_APP_WEBSITE
        : location.host
          .replace('m.', '')
          // .replace('www.', '')
          .replace('v2.', '')
      return website
    },

    showPwdTipStatus() {
      return this.pwdTipText
    },
    // 推廣代碼欄位是否 disabled
    agentCodeDisable() {
      // 若網址列有帶 agent_code或session有存agentCode，則不給使用者修改
      const agentCodeSession = sessionStorage.getItem('agentCode')
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'agent_code') || agentCodeSession) {
        if (this.$route.query.agent_code || agentCodeSession) {
          return true
        }
      }
      return false
    },
  },

  watch: {
    'registerFailDialog.status'(newStatus) {
      if (newStatus) return false
      this.registerFailDialog.text = null
    },
  },

  mounted() {
    this.getPhoneCountryCode() // 取得手機國碼
    this.identifyReset() // 動置驗證碼
    this.getTypeDoc('business') // 取得文件
    this.recommendCodeInSide(this.$route.query, this.form) // 如果有推薦碼
    this.agentCodeInSide(this.$route.query, this.form) // 如果有代理碼

    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
  },

  methods: {
    ...mapActions([
      'user_login',
      'user_register',
      'show_alert',
      'get_type_doc',
      'user_logout',
      'user_agent_code',
      'get_phone_country_code',
      'verify_mobile_code',
    ]),
    handlerVerifiMobileCode(funcName) {
      if (funcName === 'verifiMobileCode') {
        // 發送驗証碼 按鈕
        verifiMobileCode(this)
        return
      }
      if (funcName === 'VerificationCodeValidation' && this.registerValidationMethod === 'sms') {
        // 驗證 sms 簡訊，成功即可註冊
        this.VerificationCodeValidation(this.form)
      } else {
        // 不用 sms 簡訊，即可註冊
        this.userRegister(this.form)
      }
    },

    /**
   * 驗證 sms 簡訊，成功即可註冊
   * @date 2022-01-14
   * @param {number} phoneCountryCode 國碼
   * @param {number} form.contact_no  手機號碼
   * @param {number} form.verifiMobileCode  手機驗證號碼
   */
    async VerificationCodeValidation(form) {
      const res = await this.verify_mobile_code({
        phone: `${this.phoneCountryCode}|${this.form.contact_no}`,
        code: form.verifiMobileCode,
      })
      resStatus(res, this.VerificationCodeValidationSuccess, this.VerificationCodeValidationFail)
    },

    /**
   * 驗證 sms 簡訊，成功即可註冊 (成功)
   * @date 2022-01-14
   * @param {array} data 成功信息
   */
    VerificationCodeValidationSuccess() {
      this.userRegister(this.form)
    },

    /**
   * 驗證 sms 簡訊，失敗不可註冊 (失敗)
   * @date 2022-01-14
   * @param {string} errorsMessage 失敗信息
   */
    VerificationCodeValidationFail(errorsMessage) {
      this.show_alert({
        icon: 'fail',
        text: this.$t('form.errorCaptcha'),
      })
      this.form.verifiMobileCode = ''
    },
    async getPhoneCountryCode() {
      // 取得手機國碼
      await this.get_phone_country_code()

      // 指定默認 國家手機碼 state.websiteData.phone_format_nation.toUpperCase()
      // TODO: 預設國家 手機號，但DM88 國家 CHINA，手機號卻是 PH，導致有問題
      // this.phoneCountryCode = this.phoneCountryCodeList.find(item => item.code === this.mobileFormatNation).country_code || ''
      this.phoneCountryCode = this.phoneCountryCodeList[0].country_code
    },

    // 點擊輸入密碼
    showPwdTipText() {
      this.pwdTipText = true
    },
    hiddenPwdTipText() {
      this.pwdTipText = false
    },

    /**
     * 如果有推薦碼寫入 form
     * @date 2021-06-01
     * @param {string} {recommend} 來自 router query 的推薦碼
     * @param {any} recommend
     */
    recommendCodeInSide({ recommend }, form) {
      if (recommend) form.recommend = recommend
    },

    /**
     * 如果有代理碼寫入 form
     * @date 2023-11-16
     * @param {string} {recommend} 來自 router query 的推薦碼
     * @param {any} recommend
     */
    agentCodeInSide({ agent_code }, form) {
      const agentCodeSession = sessionStorage.getItem('agentCode')
      if (agent_code || agentCodeSession) {
        form.agentCode = agent_code || agentCodeSession
        // 若網址有代理推薦碼,且沒存過session,則存入session
        if (agent_code && !agentCodeSession) this.user_agent_code(agent_code)
      }
    },

    resetForm() {
      this.$refs.form.reset() // 表單重置
      Object.assign(this.form, this.$options.data().form)
    },

    /**
     * 註冊帳號 (成功)
     * @date 2021-05-27
     */
    registerSuccess() {
      this.show_alert({
        icon: 'done',
        text: this.$t('flashMessage.registerDone'),
      })

      this.identifyReset() // 驗證碼重置
      this.resetForm()
      this.$router.push({ path: '/login' })
    },

    /**
     * 註冊帳號 (失敗)
     * @date 2021-05-27
     * @param {any} errorText
     */
    registerFail(errorText) {
      this.registerFailDialog.text = errorText
      this.registerFailDialog.status = true

      this.identifyReset() // 驗證碼重置
      this.resetForm()
    },

    /**
     * 註冊帳號
     * @date 2021-05-27
     */
    async userRegister(form) {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.userRegister.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.userRegister.setStatus(false)
      this.form.contact_no = `${this.phoneCountryCode}|${this.form.contact_no}`
      console.log(' this.form.contact_no this.form.contact_no this.form.contact_no', this.form.contact_no)
      const { account, password, password_confirmation, website, recommend, agentCode, contact_no } = form
      const userForm = {
        account,
        password,
        password_confirmation,
        website,
        contact_no,
      }
      if (recommend) userForm.recommend = recommend
      if (agentCode) userForm.agent_code = agentCode

      const res = await this.user_register(userForm)
      resStatus(res, this.registerSuccess, this.registerFail)
    },

    // -------- 驗證碼 ----------------
    // 驗證碼重置 && 点击验证码刷新验证码
    identifyReset(type) {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
      if (type) this.$refs.form.validate()
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    },

    /**
     * 依類別取得文件
     * @date 2021-05-11
     * @param {string} type 類別
     */
    async getTypeDoc(type) {
      const res = await this.get_type_doc(type)

      // 企業事務 文檔
      const rulesDetails = this.filterRulesDoc(res.docs.find(item => item.type === 'business'))

      this.rulesDialog.title = rulesDetails.name
      // 去除字符 轉換為斷落
      this.rulesDialog.content = rulesDetails.content.split('\r\n')
    },

    /**
     * 篩選指定文件 (規則與條款)
     * @date 2021-08-16
     * @param {any} docs
     * @returns {any}
     */
    filterRulesDoc({ docs }) {
      return docs[1].docs[0]
    },

    rulesDialogClose() {
      this.rulesDialog.status = false
      this.form.checkAge = true
    },

    async userLogout() {
      const res = await this.user_logout()
      resStatus(res, this.userLogoutSuccess, this.failDialog)
    },

    userLogoutSuccess(data) {
      this.userBaseInfo = []
      this.show_alert({
        icon: 'done',
        // text: data.message,
      })
    },

    failDialog(error) {
      this.show_alert({
        icon: 'fail',
        text: error,
      })
    },
  },
}
