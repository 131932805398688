var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "pa-0" },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "pa-0 info--text text-center mb-2 d-flex justify-center align-center",
                  attrs: { cols: "12" },
                },
                [
                  _c("span", { staticClass: "caption px-3 comment--text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("form.continueWith")) + " "),
                  ]),
                ]
              ),
              _vm.googleSignInDisplay && !_vm.googleAuthError && !_vm.isWebview
                ? _c(
                    "v-col",
                    {
                      staticClass: "pa-0 mr-1 google rounded",
                      attrs: { id: "google-auth-button-col" },
                    },
                    [
                      _c("div", {
                        ref: "googleAuth",
                        staticClass: "google g_id_signin",
                        attrs: { id: "google-login-button" },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "google-icon-area d-flex justify-center align-center",
                        },
                        [
                          _c("img", {
                            attrs: {
                              id: "google-icon",
                              width: "24",
                              src: _vm.socialMediaItem["google"],
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.googleSignInDisplay && _vm.device === "ios" && _vm.isWebview
                ? _c(
                    "v-col",
                    {
                      staticClass: "pa-0 mr-1 google rounded",
                      attrs: { id: "google-auth-button-col" },
                      on: { click: _vm.iosWebviewEvent },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "google-icon-area d-flex justify-center align-center w-100 h-100",
                          attrs: { id: "google-auth-button-ios" },
                        },
                        [
                          _c("img", {
                            attrs: {
                              id: "google-icon",
                              width: "24",
                              src: _vm.socialMediaItem["google"],
                            },
                            on: { click: _vm.iosWebviewEvent },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.googleSignInDisplay &&
              _vm.device === "android" &&
              _vm.isWebview
                ? _c(
                    "v-col",
                    {
                      staticClass: "pa-0 mr-1 google rounded",
                      attrs: { id: "google-auth-button-col" },
                      on: { click: _vm.androidWebviewEvent },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "google-icon-area d-flex justify-center align-center",
                          attrs: { id: "google-auth-button-android" },
                        },
                        [
                          _c("img", {
                            attrs: {
                              id: "google-icon",
                              width: "24",
                              src: _vm.socialMediaItem["google"],
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.appleSignInDisplay && !_vm.appleAuthError
                ? _c(
                    "v-col",
                    {
                      staticClass: "pa-0 ml-1 apple rounded overflow-hidden",
                      attrs: { id: "apple-auth-button-col" },
                    },
                    [
                      _c("div", [
                        _c("div", {
                          attrs: {
                            id: "appleid-signin",
                            "data-color": "black",
                            "data-height": _vm.layout === "template3" ? 60 : 40,
                            "data-type": "sign in",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "apple-icon-area d-flex justify-center align-center",
                          },
                          [
                            _c("img", {
                              attrs: {
                                id: "google-icon",
                                width: "24",
                                src: _vm.socialMediaItem["apple"],
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("Dialog", { attrs: { dialog: _vm.errorDialog.status, width: 280 } }, [
        _c(
          "div",
          { staticClass: "px-6 mb-0", attrs: { slot: "text" }, slot: "text" },
          [
            _c(
              "p",
              { staticClass: "text-center" },
              [
                _c("Icon", {
                  staticClass: "danger--text",
                  attrs: {
                    data: require("@icon/alert/error.svg"),
                    width: "30px",
                    height: "30px",
                  },
                }),
              ],
              1
            ),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("form.mediaSignInFail", {
                      media: _vm.errorSocialMedia,
                    })
                  ) +
                  " "
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }