<template>
  <div id="login" class="vh-100">
    <v-form ref="form" v-model="form.veridate">
      <v-container>
        <v-row class="px-1">
          <!-- 頭像 -->
          <v-col cols="12" class="mt-5 mb-2">
            <div class="logo d-flex justify-center">
              <v-img
                v-if="websiteLogo"
                :src="baseImgUrl + websiteLogo"
                max-width="72"
              />
            </div>

            <h1 v-if="!websiteLogo" class="text-center title--text">
              {{ websiteName }}
            </h1>
          </v-col>

          <v-col cols="12" class="py-1">
            <v-text-field
              id="acc"
              v-model="form.userName"
              :rules="[rules.requiredAcc]"
              autofocus
              :placeholder="$t('form.missAccount')"
              outlined
              autocapitalize="off"
              autocorrect="off"
              filled
              dense
            >
              <div slot="prepend-inner" class="mt-0">
                <Icon
                  data="@icon/bottomNav/profile.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>
            </v-text-field>
          </v-col>

          <v-col cols="12" class="py-1">
            <v-text-field
              id="pwd"
              v-model="form.password"
              :rules="[rules.requiredPwd]"
              :type="form.showPassword ? 'text' : 'password'"
              :placeholder="$t('form.missPassword')"
              outlined
              filled
              dense
              @keydown.enter="userLogin"
            >
              <div slot="prepend-inner" class="mt-0">
                <Icon
                  data="@icon/password.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>

              <!-- append -->
              <div
                slot="append"
                @click="form.showPassword = !form.showPassword"
              >
                <Icon
                  :data="form.showPassword ? eyeOpen : eyeClose"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 記住我 -->
          <v-col id="remember" class="py-0">
            <v-checkbox v-model="form.rememberMe" dense class="mt-0 py-0">
              <div slot="label" class="primary--text caption">
                {{ $t('form.rememberMe') }}
              </div>
            </v-checkbox>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-btn
              height="44px"
              :disabled="!form.veridate"
              block
              color="primary"
              @click="userLogin"
            >
              <span class="btnText--text subtitle-1">
                {{ $t('subHeader.login') }}
              </span>
            </v-btn>

            <!-- 第三方登入 -->
            <MediaLogin v-if="socialDisplay" />
          </v-col>

          <!-- 其它導向文字 -->
          <v-col class="pt-0 d-flex subtitle-2">
            <!-- 忘記密碼 -->
            <span
              class="link--text caption cursor-pointer"
              @click="forgotPassword"
            >
              {{ $t('subHeader.forgetPassword') }} ?
            </span>

            <v-spacer />

            <!-- 註冊帳號 -->
            <router-link
              v-if="registerStatus"
              to="/register"
              class="text-decoration-none caption"
            >
              <span class="title--text">
                {{
                  $t('registerAndLogin.noAccount') + ' ' + $t('global.please')
                }}
              </span>
              {{ $t('registerAndLogin.switchRegister') }}
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- 先去逛逛 -->
    <div class="hang-out-btn w-100 text-center">
      <router-link to="/" class="text-decoration-none">
        <v-btn rounded depressed color="rgba(234, 234, 234, 0.12)">
          <span class="babyBlue--text subtitle-2">
            {{ $t('registerAndLogin.hangOut') }}
          </span>
        </v-btn>
      </router-link>
    </div>

    <Dialog :dialog="dialog.status" :width="280" :height="140">
      <div slot="text" class="px-6">
        {{ $t('flashMessage.accountOrPwError') }}
      </div>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="dialog.status = !dialog.status"
      >
        {{ $t('global.tryAgain') }}
      </v-btn>
    </Dialog>

    <!-- forgot password dialog 忘記密碼 彈窗 -->
    <Dialog
      :width="280"
      :dialog="forgotPasswordDialog.status"
      @update-dialog="changeForgotDialog"
    >
      <div slot="text" class="px-6 subtitle-1 font-weight-bold">
        <!--------------------------- 忘記密碼 (發送驗證碼) ----------------------->
        <div v-show="!forgotPasswordDialog.resetStatus">
          <p class="icon--text">
            {{ $t('subHeader.forgetPassword') }}
          </p>

          <v-form
            ref="verify1"
            v-model="forgotPasswordDialog.verify1"
            lazy-validation
          >
            <!-- 帳號 -->
            <span class="title--text caption font-weight-medium">
              {{ $t('subHeader.account') }}
            </span>
            <v-text-field
              v-model.trim="forgotPasswordDialog.form.account"
              dense
              :placeholder="$t('form.missAccount')"
              :rules="[rules.requiredAcc]"
              single-line
              outlined
            />

            <!-- 如果手機驗證 -->
            <div v-if="validateType === 'sms'">
              <!-- 手機驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t('form.verifySMS') }}
              </span>

              <!-- 手機國碼 -->
              <v-select
                v-model="phoneCountryCode"
                :items="phoneCountryCodeList"
                item-text="country_label"
                item-value="country_code"
                label="Select"
                class="mt-2"
                dense
                single-line
                outlined
              />

              <v-text-field
                v-model="forgotPasswordDialog.form.phone"
                dense
                forgot-password-dialog
                :placeholder="$t('form.missMobile')"
                :rules="[rules.requiredPhone]"
                single-line
                outlined
              />
            </div>

            <!-- 如果 email 驗證 -->
            <div v-if="validateType === 'email'">
              <!-- 信箱驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t('form.verifyEmail') }}
              </span>
              <v-text-field
                v-model.trim="forgotPasswordDialog.form.email"
                dense
                :placeholder="$t('form.missEmail')"
                :rules="[rules.requiredEmail, rules.isEmail]"
                single-line
                outlined
                @keydown.enter="sendVerifyCode"
              />
            </div>
          </v-form>

          <!-- 發送驗證碼 -->
          <div class="text-right">
            <v-btn
              class="pa-0 caption"
              text
              color="primary"
              :disable="true"
              @click="sendVerifyCode"
            >
              {{ $t('myCenter.sendVerificationCode') }}
            </v-btn>
          </div>
        </div>

        <!--------------------------- 重設密碼 ----------------------->
        <div v-show="forgotPasswordDialog.resetStatus">
          <p class="icon--text">
            {{ $t('form.resetPassword') }}
          </p>

          <v-form
            ref="verify2"
            v-model="forgotPasswordDialog.verify2"
            lazy-validation
          >
            <!-- 驗證碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t('form.missCaptcha') }}
            </span>
            <v-container class="py-0">
              <v-row class="">
                <v-col
                  :cols="itemsWidth"
                  class="px-0"
                  :class="clientLang === 'ph' ? 'pb-0' : 'pr-1'"
                >
                  <v-text-field
                    v-model.trim="forgotPasswordDialog.form.verifyCode"
                    dense
                    :placeholder="$t('form.missVerifyCode')"
                    :rules="[rules.requiredVerifyCode]"
                    single-line
                    outlined
                  />
                </v-col>

                <v-col
                  :cols="itemsWidth"
                  class="px-0"
                  :class="clientLang === 'ph' ? 'pt-0' : 'pl-1'"
                >
                  <v-btn
                    block
                    outlined
                    color="primary"
                    class="px-0 caption"
                    :disabled="forgotPasswordDialog.resendVerifyCode.num < 60"
                    @click="sendVerifyCode"
                  >
                    <div
                      v-show="forgotPasswordDialog.resendVerifyCode.num === 60"
                    >
                      {{ $t('global.resend') }}
                    </div>
                    <div
                      v-show="forgotPasswordDialog.resendVerifyCode.num < 60"
                    >
                      {{
                        forgotPasswordDialog.resendVerifyCode.num +
                        's ' +
                        $t('global.retry')
                      }}
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>

            <!-- 新登入密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t('form.newPassword') }}
            </span>
            <v-text-field
              v-model.trim="forgotPasswordDialog.form.newPassword"
              type="password"
              dense
              :placeholder="$t('form.enterNewPassword')"
              :rules="[rules.requiredNewPassword, rules.newPasswordFormat]"
              single-line
              outlined
            />

            <div>
              <!-- 再次輸入新登入密碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t('form.newPasswordConfirm') }}
              </span>
              <v-text-field
                v-model.trim="forgotPasswordDialog.form.newPasswordConfirm"
                dense
                type="password"
                :placeholder="$t('form.enterNewPasswordAgain')"
                :rules="[
                  rules.requiredNewPasswordConfirm,
                  rules.passwordConfirm,
                ]"
                single-line
                outlined
              />
            </div>
          </v-form>

          <!-- 操作 -->
          <div class="text-right">
            <!-- 上一步 -->
            <v-btn
              class="pa-0 subtitle-2 subtitle--text mr-2"
              text
              @click="prevStep"
            >
              {{ $t('form.prev') }}
            </v-btn>

            <!-- 確認 -->
            <v-btn
              class="pa-0 subtitle-2"
              text
              color="primary"
              @click="resetPasswordSubmit"
            >
              {{ $t('global.confirmation') }}
            </v-btn>
          </div>
        </div>
      </div>
    </Dialog>

    <!-- 重設密碼失敗內容 -->
    <Dialog
      :dialog="forgotPasswordDialog.resetFailDialog.status"
      :width="280"
      :height="140"
    >
      <div slot="text" class="px-6">
        {{ forgotPasswordDialog.resetFailDialog.content }}
      </div>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="forgotPasswordDialog.resetFailDialog.content = ''"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>
  </div>
</template>

<script>
import loginMixins from '../mixins/loginMixins'

export default {
  mixins: [loginMixins],
}
</script>

<style lang="scss" scoped>
.hang-out-btn {
  position: fixed;
  bottom: 22px;
  left: 0;
}
</style>
