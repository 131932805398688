var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100dvh over-flow-y-scroll", attrs: { id: "register" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.form.veridate,
            callback: function ($$v) {
              _vm.$set(_vm.form, "veridate", $$v)
            },
            expression: "form.veridate",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "px-1" },
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-5 mb-2 py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "logo d-flex justify-center" },
                        [
                          _vm.websiteLogo
                            ? _c("v-img", {
                                attrs: {
                                  src: _vm.baseImgUrl + _vm.websiteLogo,
                                  "max-width": "72",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      !_vm.websiteLogo
                        ? _c("h1", { staticClass: "text-center title--text" }, [
                            _vm._v(" " + _vm._s(_vm.websiteName) + " "),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-text-field",
                        {
                          attrs: {
                            height: "40px",
                            rules: [
                              _vm.rules.require.acc,
                              _vm.rules.length.acc,
                              _vm.rules.format.acc,
                            ],
                            autofocus: "",
                            placeholder: _vm.$t("form.missAccount"),
                            outlined: "",
                            autocapitalize: "off",
                            autocorrect: "off",
                            dense: "",
                          },
                          model: {
                            value: _vm.form.account,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "account", $$v)
                            },
                            expression: "form.account",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-0",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner",
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: require("@icon/bottomNav/profile.svg"),
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-text-field",
                        {
                          attrs: {
                            height: "40px",
                            rules: [_vm.rules.require.pwd],
                            type: _vm.form.showPassword ? "text" : "password",
                            "append-icon": _vm.form.showPassword
                              ? "mdi-eye"
                              : "mdi-eye-off",
                            placeholder: _vm.$t("form.missPassword"),
                            outlined: "",
                            dense: "",
                          },
                          on: {
                            "click:append": function ($event) {
                              _vm.form.showPassword = !_vm.form.showPassword
                            },
                            focus: _vm.showPwdTipText,
                            blur: _vm.hiddenPwdTipText,
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "password", $$v)
                            },
                            expression: "form.password",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-0",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner",
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: require("@icon/password.svg"),
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "append" },
                              on: {
                                click: function ($event) {
                                  _vm.form.showPassword = !_vm.form.showPassword
                                },
                              },
                              slot: "append",
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: _vm.form.showPassword
                                    ? _vm.eyeOpen
                                    : _vm.eyeClose,
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "pa-0 pl-2 caption",
                          class: { "d-none": !_vm.showPwdTipStatus },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("form.errorPwdFormat", {
                                  min: _vm.pwdRules.size_min,
                                  max: _vm.pwdRules.size_max,
                                })
                              ) +
                              " "
                          ),
                          _vm._l(
                            _vm.pwdRules.requirement,
                            function (item, index) {
                              return _c("span", { key: index }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t(`form.${item}`)) + " "
                                ),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-text-field",
                        {
                          attrs: {
                            height: "40px",
                            rules: [
                              _vm.rules.require.confirmPwd,
                              _vm.rules.verify.confirmPwd,
                            ],
                            type: _vm.form.showPasswordConfirmation
                              ? "text"
                              : "password",
                            placeholder: _vm.$t("form.confirmPasswordAgain"),
                            outlined: "",
                            dense: "",
                          },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.userLogin.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.form.password_confirmation,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "password_confirmation", $$v)
                            },
                            expression: "form.password_confirmation",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-0",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner",
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: require("@icon/password_confirmation.svg"),
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "append" },
                              on: {
                                click: function ($event) {
                                  _vm.form.showPasswordConfirmation =
                                    !_vm.form.showPasswordConfirmation
                                },
                              },
                              slot: "append",
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: _vm.form.showPasswordConfirmation
                                    ? _vm.eyeOpen
                                    : _vm.eyeClose,
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.registerValidationMethod === "sms"
                    ? _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-select",
                            {
                              attrs: {
                                items: _vm.phoneCountryCodeList,
                                "item-text": "country_label",
                                "item-value": "country_code",
                                label: _vm.$t("form.countryCodes"),
                                dense: "",
                                "single-line": "",
                                outlined: "",
                                disabled: _vm.disable.contact_no,
                              },
                              model: {
                                value: _vm.phoneCountryCode,
                                callback: function ($$v) {
                                  _vm.phoneCountryCode = $$v
                                },
                                expression: "phoneCountryCode",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mt-0",
                                  attrs: { slot: "prepend-inner" },
                                  slot: "prepend-inner",
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "primary--text",
                                    attrs: {
                                      data: require("@icon/phone.svg"),
                                      width: "24px",
                                      height: "24px",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.registerValidationMethod === "sms"
                    ? _c(
                        "v-col",
                        { staticClass: "py-0 pr-0", attrs: { cols: "7" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              height: "40px",
                              placeholder: _vm.$t("form.missMobile"),
                              rules:
                                _vm.registerValidationMethod === "sms"
                                  ? [_vm.rules.require.mobile]
                                  : [],
                              outlined: "",
                              dense: "",
                            },
                            model: {
                              value: _vm.form.contact_no,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contact_no", $$v)
                              },
                              expression: "form.contact_no",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.registerValidationMethod === "sms"
                    ? _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "5" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-0 caption",
                              attrs: {
                                disabled: _vm.disable.verifiMobileCode,
                                block: "",
                                outlined: "",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handlerVerifiMobileCode(
                                    "verifiMobileCode"
                                  )
                                },
                              },
                            },
                            [
                              _vm.verifiMobileCodeBtnSec === 60
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "myCenter.sendVerificationCode"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.verifiMobileCodeBtnSec +
                                            "s " +
                                            _vm.$t("global.retry")
                                        ) +
                                        " "
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-text-field",
                        {
                          attrs: {
                            height: "40px",
                            rules: [
                              _vm.rules.require.missMobileVerifyCode,
                              _vm.rules.length.missMobileVerifyCode,
                            ],
                            placeholder: _vm.$t("form.missMobileVerifyCode"),
                            outlined: "",
                            dense: "",
                          },
                          model: {
                            value: _vm.form.verifiMobileCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "verifiMobileCode", $$v)
                            },
                            expression: "form.verifiMobileCode",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-0",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner",
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: require("@icon/mobileVerify.svg"),
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-text-field",
                        {
                          attrs: {
                            height: "40px",
                            autofocus: "",
                            placeholder: _vm.$t("form.missAgentCode"),
                            outlined: "",
                            autocapitalize: "off",
                            autocorrect: "off",
                            dense: "",
                            disabled: _vm.agentCodeDisable,
                          },
                          model: {
                            value: _vm.form.agentCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "agentCode", $$v)
                            },
                            expression: "form.agentCode",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-0",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner",
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: require("@icon/recommend.svg"),
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-text-field",
                        {
                          attrs: {
                            height: "40px",
                            rules: [
                              _vm.rules.require.verifyCode,
                              _vm.rules.verify.verifyCode,
                            ],
                            placeholder: _vm.$t("form.missVerifyCode"),
                            outlined: "",
                            inputmode: "decimal",
                            dense: "",
                          },
                          model: {
                            value: _vm.form.verifyCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "verifyCode", $$v)
                            },
                            expression: "form.verifyCode",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-0",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner",
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: require("@icon/verify.svg"),
                                  width: "24px",
                                  height: "24px",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "identifyArea pa-0 ma-0 ma-0",
                              attrs: { slot: "append" },
                              on: {
                                click: function ($event) {
                                  return _vm.identifyReset(true)
                                },
                              },
                              slot: "append",
                            },
                            [
                              _c("Identify", {
                                attrs: { "identify-code": _vm.identifyCode },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("span", { staticClass: "caption comment--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("form.missVerifyCodeFormat")) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0" },
                    [
                      _c(
                        "v-checkbox",
                        {
                          staticClass: "ma-0 chk-age",
                          attrs: { rules: [_vm.rules.require.age] },
                          model: {
                            value: _vm.form.checkAge,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "checkAge", $$v)
                            },
                            expression: "form.checkAge",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "caption primary--text",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .$t("form.registerChecked", {
                                          name: _vm.websiteName,
                                        })
                                        .substr(0, 11)
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "ml-1 caption link--text text-decoration-underline cursor-pointer",
                                  on: {
                                    click: function ($event) {
                                      _vm.rulesDialog.status = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .$t("form.registerChecked", {
                                            name: _vm.websiteName,
                                          })
                                          .substr(11)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            height: "44",
                            disabled: !_vm.form.veridate,
                            block: "",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handlerVerifiMobileCode(
                                "VerificationCodeValidation"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("subHeader.register")) + " "
                          ),
                        ]
                      ),
                      _vm.socialDisplay ? _c("MediaLogin") : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-col", [
                    _c(
                      "div",
                      { staticClass: "hang-out-btn w-100 text-center" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "text-decoration-none",
                            attrs: { to: "/" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  rounded: "",
                                  depressed: "",
                                  color: "rgba(234, 234, 234, 0.12)",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "babyBlue--text subtitle-2" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("registerAndLogin.hangOut")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "10" } },
                    [
                      _c(
                        "p",
                        { staticClass: "caption" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("registerAndLogin.switchLogin")) +
                              " "
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "text-decoration-none",
                              attrs: { to: "/login" },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("subHeader.login"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.dialog.status, width: 280 } },
        [
          _c(
            "span",
            { staticClass: "px-6", attrs: { slot: "text" }, slot: "text" },
            [_vm._v(_vm._s(_vm.dialog.text))]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", color: "danger", text: "" },
              on: {
                click: function ($event) {
                  _vm.dialog.status = !_vm.dialog.status
                },
              },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", color: "primary", text: "" },
              on: {
                click: function ($event) {
                  _vm.dialog.status = !_vm.dialog.status
                },
              },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.tryAgain")) + " ")]
          ),
        ],
        1
      ),
      _vm.rulesDialog
        ? _c(
            "Dialog",
            { attrs: { width: 320, dialog: _vm.rulesDialog.status } },
            [
              _c(
                "span",
                {
                  staticClass: "subtitle-1 title--text",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v(" " + _vm._s(_vm.rulesDialog.title) + " ")]
              ),
              _c("div", { attrs: { slot: "text" }, slot: "text" }, [
                _c("div", { staticClass: "px-6 rules__content" }, [
                  _c(
                    "ul",
                    { staticClass: "pl-0 caption text--text" },
                    _vm._l(_vm.rulesDialog.content, function (item, index) {
                      return _c("li", { key: index, staticClass: "pb-2" }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: { slot: "btn", text: "", color: "primary" },
                  on: { click: _vm.rulesDialogClose },
                  slot: "btn",
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.isLogin, height: 140 } },
        [
          _c(
            "div",
            {
              staticClass: "px-6 text--text",
              attrs: { slot: "text" },
              slot: "text",
            },
            [_vm._v(" " + _vm._s(_vm.$t("flashMessage.isLoginStatus")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mb-2 text--text",
              attrs: { slot: "btn", text: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push({ path: "/" })
                },
              },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mb-2",
              attrs: { slot: "btn", text: "", color: "primary" },
              on: { click: _vm.userLogout },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            dialog: _vm.registerFailDialog.status,
            width: 280,
            height: 140,
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-6", attrs: { slot: "text" }, slot: "text" },
            [_vm._v(" " + _vm._s(_vm.registerFailDialog.text) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: {
                click: function ($event) {
                  _vm.registerFailDialog.status = !_vm.registerFailDialog.status
                },
              },
              slot: "btn",
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.tryAgain")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }